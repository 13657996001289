/* /////////////////////////////////live tv/////////////////////////////////////////// */

.dragOuter {
  width: 320px;
  min-height: 180px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  background-color: #fff;
  position: fixed;
  top: calc(100vh - 230px);
  right: 20px;
  z-index: 99999;
  display: none;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3px 3px 0 0;
}

.dragOuter.show-tv {
  display: flex;
  animation: sl-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.dragOuter iframe {
  width: 100%;
  flex: 1;
}

.dragOuter-toggler {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0px 16px rgba(0, 0, 0, 0.3), 0 0px 8px rgba(0, 0, 0, 0.3) !important;
  background-color: #4169e1;
  position: fixed;
  right: 50px;
  bottom: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dragOuter-toggler.showToggler {
  display: flex;
  animation: jl-horizontal 1s 2s 2 both;
}

.jello-horizontal {
  animation: jl-horizontal 1s 2s 2 both;
}

.dragOuter-toggler svg {
  width: 25px;
  height: 25px;
}

.dragOuter-toggler svg g,
.dragOuter-toggler svg path {
  fill: #e9e9e9;
}

@keyframes jl-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes sl-in-bottom {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (orientation: landscape) and (max-width: 991px) {
  .dragOuter {
    position: fixed;
  }
}

@media (max-width: 991px) {
  .dragOuter {
    z-index: 1 !important;
  }
}

@media (max-width: 767px) {
  .dragOuter {
    position: inherit !important;
    width: calc(100% + 20px) !important;
    margin-right: -10px;
    margin-left: -10px;
    margin-top: 15px;
    z-index: 1 !important;
  }

  .dragOuter-toggler {
    right: 15px;
    bottom: 15px;
    z-index: 2;
  }

  .dragOuter.show-tv {
    animation: inherit !important;
  }
}

.dragOuter-toggler {
  background-color: #4169e1;
}

.dragOuter-toggler svg path {
  fill: #e9e9e9;
}

@media all and (min-width: 767px) {
  .dragOuter-toggler {
    z-index: 2 !important;
  }
}

@media (max-width: 767px) {
  .mob_view .video_modalNew {
    position: relative;
    bottom: 0;
    right: 0;
    margin-bottom: 15px;
  }
}

.video_modalNew {
  position: fixed;
  bottom: 46px;
  right: 13px;
  z-index: 1;
}

.iframe_close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  text-align: center;
}

.iframe_close i {
  cursor: pointer;
  text-align: center;
  padding: 8px;
  width: 35px;
  height: 35px;
  color: black;
  font-size: 20px;
}

.tviframe {
  min-height: 250px !important;
}

@media (min-width: 300px) and (max-width: 567px) {
  .tvifram {
    width: 100%;
    min-height: 250px !important;
  }
}

@media (min-width: 568px) and (max-width: 767px) {
  .tvifram {
    width: 100%;
    min-height: 250px !important;
  }
}

/* /////////////////////////////////live tv/////////////////////////////////////////// */
.bet_select {
  max-width: 235px;
  margin: 0px 16px;
  height: 45px;
}
.bet_select_box {
  display: flex;
  justify-content: end;
  align-items: baseline;
  margin: 10px 0 10px;
}

.rollback_text {
  font-size: 20px;
  font-weight: 600;
}