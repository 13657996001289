.dt-button{
    background-image: linear-gradient(to bottom, #fff 0%, #e9e9e9 100%) !important;
}
.table-nowrap{
    white-space: nowrap;
}



th{
    padding:8px 4px !important;
}
td{
    padding:8px 4px !important;
}