.marquee_score_widget {
    border: 0px solid #ccc !important;
    background: #ffffff !important;
    color: #267531 !important;
    /* padding: 0px 5px !important; */
    margin-right: 0 !important;
    margin-bottom: 13px !important;
    /* margin: 5px auto !important; */
    border-radius: 2px !important;
    box-shadow: 0 3px 20px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
    text-shadow: 2px 3px 2px #ececec !important;
    font-size: 15px;
    padding: 3px;
    font-weight: 600;
}
.confirm-box__content {
    position: fixed !important;
    top: 15% !important;
    left: 55% !important;
    transform: translate(-55%);
   

}
@media screen and (max-width:768px){
    .confirm-box__content {
        left: 50% !important;

    }
}

