.dd{
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    line-height: 20px;
}
.dd-list{
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}
.dd-item{
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    font-size: 13px;
    line-height: 20px;
}

.dd-handle {
    display: block;
    margin: 5px 0;
    padding: 5px 10px;
    color: #333;
    text-decoration: none;
    border: 1px solid #e7eaec;
    background: #f5f5f5;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.dd-handle {
    width: 300px;
    text-align: center;
}
.dd-handle.active-lang {
    background-color: #6c8dab;
    color: #fff;
    font-weight: bold;
}