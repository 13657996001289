.MatchNameScore {
    display: flex;
    align-items: center;
    width: 100%;
    /* border: 1px solid; */
    padding: 12px;
    margin-top: 0px;
    background: #19828d;
    height: 44px;
    font-weight: 600;
    color: #ffff;
}

.matchScore {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* border: 1px solid; */
    padding: 12px;
    margin-top: 0px;
    background: aliceblue;
    height: 44px;
}

.Score_board_div {
    display: flex;
    justify-content: space-between;

}

.score2_table tr {
    text-align: initial;
}

.scoreBall_list ul {
    list-style: none;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    margin-top: 0;
    margin-bottom: 0px !important;
    margin-left: -40px;

}

.scoreList_ball {
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    gap: 4px;

}

.scoreList_ball span {
    border-bottom: 2px solid #ddd;
    width: 100%;
    padding-bottom: 0px;
    padding-left: 10px;
    display: inline-block;
    min-height: 34px;
}

.scoreList_ball ul {

    width: 100%;

    align-items: center;
}


.scoreBall_list ul li span {
    width: 20px;
    min-height: auto;
    height: 20px;
    background-color: #19828d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 12px;
    text-align: center;
    font-size: 10px;
}

.scoreBall_list {
    flex: 0 0 50%;
}

.LastBall {
    margin-right: 10px;
}

.tableHeadof_score th {
    background: #0abacd !important;
    color: #fff;
}

.tableHeadof_score th:first {
    width: 10%;
}

tr.tableHeadof_score th:first-child {
    width: 30%;
    padding: 8px 10px !important;
}

.score2_table>tbody>tr>td:first-child {
    padding: 8px 10px !important;
}

.anvWidget {
    z-index: 1 !important;
    overflow: auto;
    display: flex !important;
}

.score2_table>tbody>tr>td,
.score2_table>tbody>tr>th,
.score2_table>tfoot>tr>td,
.score2_table>tfoot>tr>th,
.score2_table>thead>tr>td,
.score2_table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.stausBx_ht {
    height: 135px !important;
    border-right: 0 !important;
}

.anvFlex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bet_status_box.match-score.content_in_play_wrapper.match-status-table.match-score-box-color.anvWidget.score_widget {
    height: 135px !important;
    width: 100% !important;
}

.bet_status_box.match-score.content_in_play_wrapper.match-status-table.match-score-box-color.anvWidget.score_widget .match-score-box-color {
    height: 100% !important;
}

.mt-zero {
    margin-top: 0 !important;
}

.flex_div_class.rowGaper {
    gap: 10px;
}

table.score2_table {
    width: 100%;
}

.anvBottom_arrow {
    display: flex;
    background: #00669d;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}


.anvBottom_arrow svg {
    font-size: 20px !important;
    /* margin-top: 0 !important; */
    margin-top: -10px !important;

    padding-bottom: 14px !important;
}

.bowler_name {
    display: flex;
    align-items: center;
    /* border-top: 1px solid #fff; */
    padding: 10px;
    background: #0abacd !important;
    color: #ffff;
    font-weight: 700;
}

.score-container {
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%) !important;
    background-color: #fff !important;
    border-radius: 2px !important;
    position: inherit !important;
    font-size: 15px;
    padding-bottom: 0px
}

.scoreboard_leftSidebar {
    height: 100%;
}

.content_matches_wrapper .match-score-box-color div.match-score-box-color {
    height: 70px;
    line-height: 20px;
    background-color: #2066a0 !important;
    width: 100% !important;
    margin: 0px auto 0 !important;
    padding: 15px !important;
    display: table-cell !important;
}

.content_matches_wrapper {
    padding: inherit !important;
    overflow: inherit !important;
    margin-top: 10px;
}

.content_matches_wrapper div.match-score-box-color {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
    border-radius: 2px !important;
}

.bet_status_box {
    /* min-height: 100%; */
    height: 100%;
    /* background-color: #3A61A2; */
    border-right: solid 10px #eee;
    padding: 0px !important;
}

.content_matches_wrapper .match-score-box-color {
    background-color: var(--body-color-primary) !important;
    /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important; */
    border-right: solid 0px #eee !important;
    width: 66% !important;
    margin-right: 0.6% !important;
}

.match-status-text {
    display: table-cell !important;
    text-align: center;
    vertical-align: middle !important;
    padding: 0px 10px !important;
}

span.match-score-span-2.font-sans-serif.anvTab_score {
    padding: 0 !important;
}

span.match-score-span-1,
span.match-score-span-2.font-sans-serif {
    border-bottom: 1px solid #89b6ce;
    padding-bottom: 2px;
}

div#score_table_383067 {
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
    padding: 10px 5px !important;
}

@media all and (max-width: 480px) {
    .Score_board_div {
        display: flex;
        flex-direction: column;

    }

    .anvFlex {
        display: flex;
        align-items: center;
        justify-content: left;
    }

    .scoreBall_list ul {
        list-style: none;
        margin-left: -0.5rem;
        display: flex;
        padding: 8px 4px;
        padding-left: 5px;
        grid-gap: 2px;
        gap: 8px;
    }

    .scoreBall_list {
        margin: 1px 0px;
        margin-left: 0.2rem;
        /* display: block; */
    }

    .scoreList_ball {
        margin-top: 6px;
        display: flex;
        flex-direction: column;
        gap: 4px;

    }

    .LastBall {
        margin-right: 0px;
    }

    table.score2_table {
        margin-left: -5px;
    }

    .scoreBall_list ul li span {
        width: 20px;
        height: 20px;
        background-color: #19828d;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding: 12px;
        text-align: center;
        font-size: 13px;
    }

    .bet_status_box.match-score.content_in_play_wrapper.match-status-table.match-score-box-color.anvWidget.score_widget {
        height: 145px !important;
    }

    .stausBx_ht {
        height: 145px !important;
    }

    .score_widget_iframe {
        height: 145px !important;
    }

    .scoreList_ball span {
        border-bottom: 1px solid #ddd;
        width: 100;
        padding-bottom: 0px;
        padding-left: 5px;
    }

    .flex_div_class.rowGaper {
        gap: 8px;
    }


}

@media (max-width:393px) {
    .bet_status_box.match-score.content_in_play_wrapper.match-status-table.match-score-box-color.anvWidget.score_widget {
        height: 145px !important;
    }

    .stausBx_ht {
        height: 145px !important;
    }

    .scoreList_ball {
        margin-top: 6px;
        display: flex;
        flex-direction: column;
        gap: 3px;

    }

    .customePeddingFormobile {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .scoreList_ball span {
        border-bottom: 1px solid #ddd;
        width: 100;
        padding-bottom: 10px;
        padding-left: 5px;
    }

}