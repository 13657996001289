.color_modal .modal-body {
    max-height: 60vh !important;
    overflow-y: auto !important;
}

.color_modal .modal-dialog {
    top: 20vh;
}

.color_modal .color-botton {
    margin-top: 10px;
    width: 80px !important;
    height: 40px;
    margin-right: 0 !important;
    cursor: pointer;
}

.color_modal .save_color {
    width: 100% !important;
    background-color: #2d3546;
    padding: 15px;
    margin: 9px 20px;
}

.color_modal .color_row {
    margin: 10px -30px 10px 70px;
}

/* h3 {
    font-weight: 700 !important;
    color: black !important;
} */

.close {
    font-size: 40px;
    font-weight: 700;
    color: black !important;
}

.selected_color div {
    width: 50px !important;
    height: 30px;
    margin-right: 0px !important;
}

.selected_color {
    margin-right: 25px !important;
}

.picked_color .even {
    border: 3px solid red;
    border-right: 0;
}

.even {
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.picked_color .odd {
    border: 3px solid red;
    border-left: 0;
}

.odd {
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

@media (max-width: 992px) {
    .color_modal .color_row {
        margin: 0px !important
    }

    .color_modal .color-botton {
        margin-top: 10px;
        width: 50% !important;
        height: 20px;
        margin-right: 0 !important;
        cursor: pointer;
    }
}

@media (min-width: 300px) and (max-width: 479px) {
    .color_col {
        width: 20%;
        float: left;
        margin: 4px !important;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .color_modal .color_row {
        margin: 0px !important;
        display: flex;
        flex-wrap: wrap;
    }

    .color_col {
        width: auto;
        flex: 0 0 24%;
        float: none;
        margin: 2px !important;
    }

    .color_modal .modal-dialog {
        width: 480px;
        margin: 0 auto;
    }
}