/* //react-select */
.reactSelect{
    padding:10px 0px;
}
.css-yk16xz-control{
    min-height: 35px !important;
}
.css-1pahdxg-control{
        box-shadow: none !important;
    border: 1px solid #ccc !important;
}

/* react date picker */
.mlDatePicker{
    background: none repeat scroll 0% 0% #fff;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
        margin: 10px 0px 0px 25px;
        max-width: 19% !important;
}
@media only screen and (max-width: 900px) and (min-width: 300px) {
    .mlDatePicker{
        background: none repeat scroll 0% 0% #fff;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid #ccc;
                margin:10px 16px 0px 16px;
                max-width: 100% !important;
    }
  }

/* cashbook ledger */
.cbDatePicker{
    background: none repeat scroll 0% 0% #fff;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
        margin: 10px 0px 0px 10px;
        max-width: 19% !important;
}

@media only screen and (max-width: 900px) and (min-width: 300px) {
    .cbDatePicker{
        background: none repeat scroll 0% 0% #fff;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid #ccc;
                margin:10px 10px 0px 10px;
                max-width: 100% !important;
    }
  }