hr{
    border-top: 1px dashed #e7eaec;
    color: #ffffff;
    background-color: #ffffff;
    height: 1px;
    margin: 20px 0
  }
  .col-form-label{
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
    font-weight: 700;
  }
  .fa-info-circle{
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    cursor: pointer;
  }