.white-container {
    background-color: #fff;
    min-height: 100px;
}

.px-2 {
    padding-right: 2rem;
    padding-left: 2rem;
}

.py-1 {
    padding-top: 0.5rem;
    padding-bottom: 00.5rem;
}

.text-center {
    text-align: center !important;
}

.btn {
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}

.commentary_container button {
    width: 170px;
    margin: 5px;
    color: #fff !important;
}


.commentary_container button.unlock-button.active-button {
    background-color: green;
    color: #fff !important;
}

.commentary_container button {
    width: 170px;
    margin: 5px;
    color: #fff !important;
}

.commentary_container button.lock-button {
    background-color: rgba(255, 0, 0, .39);
}

.commentary_container button.unlock-button {
    background-color: rgba(0, 128, 0, .445);
}

.commentary_container button.break-button {
    background-color: rgba(0, 0, 255, .514);
}

.input_form{
    display: flex;
    justify-content: center;
}
.input_form_div{
    width: 65%;
}
.input_form_div div{
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}