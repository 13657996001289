.DashBoardCardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 1em;
}

.DashboardCard {
  padding: 1em 1.5em;
  /* border: 1px solid red; */
  background: #fff;
  border-radius: 4px;
}
