.matka_container {
  background: #fff;
  padding: 13px 0;
  margin-bottom: 20px;
}
.matka_market_container {
  margin: 15px 10px;
  grid-template-rows: repeat(9, 1fr);
  grid-auto-flow: column !important;
  display: grid;
  grid-row-gap: 2px;
  grid-column-gap: 15px;
  flex-wrap: wrap;
}

.runner_value_singlepatti {
  grid-template-rows: repeat(12, 1fr);
}

.runner_value_Singleakda {
  grid-template-rows: repeat(1, 1fr);
}

.runner_value_jodi {
  grid-template-rows: repeat(10, auto);
}

.winner_box.matka_market_box {
  background: #aaffaa;
}

.matka_market_box {
  padding: 20px 10px;
  background: #79a6ff45;
  border: 1px solid #fff;
  box-shadow: 0 3px 5px 0 rgb(16 16 16 / 15%);
  margin-top: 8px;
  color: #000 !important;
  flex: 0 0 calc(10% - 13.5px);
}

.matka_market_box p {
  margin: 0;
  align-items: baseline;
  display: flex;
  justify-content: center;
}

p.matka_market_bhav {
  font-weight: 700;
  border-bottom: 1px solid #938f8f;
  line-height: 20px;
}

.justify_center {
  justify-content: center;
}

.separator_box_line {
  display: none;
}

.matka_market_value {
  font-weight: 900;
  color: #ff0000;
  font-size: 15px;
}

@media only screen and (max-width: 625px) and (min-width: 200px) {
  .matka_market_box {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.33% - 10px);
  }
  .matka_market_container {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .separator_box_line {
    display: block;
    flex-basis: calc(100% - 5px);
    height: 1px;
    background: #a7a6a9;
    margin: 15px 0 5px 0;
  }
  .runner_value_jodi {
    grid-column-gap: 10px !important;
  }
  .jodi_box {
    flex-basis: calc(20% - 10px);
  }
}
